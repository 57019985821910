<!--
 * @Description: 查询结果
 * @Author: zhang zhen
 * @Date: 2023-02-08 17:21:45
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-07-06 17:13:28
 * @FilePath: /page-sass/src/views/searchResult/index.vue
-->
<template>
  <div class="container-view">
    <SearchSelection :formType="formType" @handleLoadData="changeParams"/>
    <ProductList v-if="dataList.length" :class="index == dataList.length - 1 && 'mb-60'" :infoData="dataList" :totalPages="totalPages" @handleLoadData="changeParams" />
    <div class="cover" v-if="!dataList.length">
      <a-empty :image="simpleImage" />
    </div>
    <!-- 侧边悬浮操作区域 -->
    <Elevator />
  </div>
</template>

<script>
import Elevator from '@/components/tools/elevator.vue'
import SearchSelection from './modules/searchSelection.vue'
import ProductList from './modules/ProductList.vue'
import { postAction } from '@/api/manage'
import { Empty } from 'ant-design-vue';
export default {
  name: 'searchResult',
  components: {
    Elevator,
    SearchSelection,
    ProductList
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      formType: 'product',
      url: {
        product: '/indexbroswer/supplier/list',
        package: '/indexbroswer/pakcing/list',
        plan: '/indexbroswer/solution/list'
      },
      dataList: [],
      pageNation: {
        pageNum: 1,
        pagesSize: 20
      },
      otherParams: {},
      totalPages: 1
    }
  },
  watch: {
    $route(e) {
      const { formType } = e.query
      formType && (this.formType = formType)
      this.handleLoadData(1)
    }
  },
  created() {
    const { formType } = this.$route.query
    formType && (this.formType = formType)
    this.handleLoadData(1)
  },
  methods: {
    handleLoadData(flag) {
      if (flag) {
        this.pageNation.pageNum = 1
        this.dataList = []
      }
      let url = this.url[this.formType]
      postAction(url, { ...this.pageNation, labelType: this.otherParams, }).then(res => {
        const { success, data } = res;
        if (success) {
          const { list, pages } = data;
          this.totalPages = pages
          let result = list
          this.dataList = result ? this.dataList.concat(result) : [];
        }
      })
    },
    changeParams(e) {
      console.log(e)
      this.otherParams = e
      this.handleLoadData(1)
    },
    handleSplitData(data) {
      // 数据拆分4个，多元数组
      let basicData = [...data],
          arrList = [],
          splitNum = parseInt(data.length / 4);
      for (let i = 1; i <= splitNum; i++) {
        // let targetArray = basicData.splice(0, 4)
        arrList.push( basicData.splice(0, 4)) 
      }
      basicData.length && arrList.push(basicData)
      return data.length ? arrList : null
    },
    handleScrollPage() {
      if (this.pageNation.pageNum >= this.totalPages) {
        return this.$message('没有更多数据')
      }
      //
      this.pageNation.pageNum ++
      // 分页
      this.handleLoadData()
    },
  },
}
</script>

<style lang="less" scoped>
.container-view {
  // min-width: 1656px;
  // width: calc(100% - 256px);
  // max-width: 1184px;
  width: 1288px;
  max-width: 1288px;
  margin: 0 auto;
  padding: 32px 0;
  .flexLayout(@direction: column; @justifyContent: flex-start;);
  .swiper-info {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 20px;
    .flexLayout(@justifyContent: flex-start;);
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    border-radius: 4px;
    &-title {
      color: #ed3f14;
      font-weight: 500;
      margin-right: 15px;
    }
    &-text {
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ant-carousel {
      flex: 1 0 0;
      min-width: 0;
      ::v-deep .slick-list {
        height: 21px !important;
      }
      ::v-deep .slick-track {
        height: auto !important;
      }
    }
  }
  .middleBanner {
    width: 1045px;
    height: 160px;
    border-radius: 8px;
    margin-bottom: 30px;
    &-img {
      width: 100%;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}
// @media (min-width: 1400px) {
//     .container-view {
//         max-width: 1280px!important;
//     }
// }
.cover {
  margin-top: 15px;
  width: 100%;
  background: #fff;
  padding: 40px 20px;
  border-radius: 6px;
  margin-bottom: 16px;
}
</style>
