<!--
 * @Description: 商品模块
 * @Author: zhang zhen
 * @Date: 2023-02-09 10:20:10
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-07-06 17:22:39
 * @FilePath: /page-sass/src/views/searchResult/modules/ProductList.vue
-->
<template>
  <div class="hotIndustry">
    <div class="hotIndustry-list">
      <div class="hotIndustry-list-item" v-for="(item, index) in infoData" :key="'infoCard' + index" @click="handleNavigatePage(item)">
        <div class="hotIndustry-list-item-banner">
          <img :src="item.mainDrawing || item.planDiagram || 'http://1.117.164.181/banner/64682d00e4b0be4bfea7da7a.png'" alt="" />
        </div>
        <h3 class="subTitle">
          {{ item.packageTitle || item.planTitle || item.businessName || '' }}
        </h3>
        <div class="design-desc">
          <img :src="item.mainDrawing || item.planDiagram || require('../../../assets/167x170.png')" alt="" class="avatar" />
          <div class="info">{{ item.detailedInformation || item.planIntroduction || item.businessName || '' }}</div>
        </div>
      </div>
    </div>
    <div class="loadArea">
      <a-button type="primary" class="load-btn" v-if="infoData.length && totalPages > infoData.length" @click="handleLoadMore">加载更多</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  props: {
    type: String,
    infoData: {
      type: Array,
      default: () => ([])
    },
    totalPages: Number
  },
  data() {
    return {}
  },
  methods: {
    handleNavigatePage(item) {
      let url = type == 'product' ? `/supplierInfo?id=122` : `/productInfo?id=2333`
      this.$router.push(url)
    },
    handleLoadMore() {
      this.$emit('handleLoadData')
    },
  }
}
</script>

<style lang="less" scoped>
.hotIndustry {
  width: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  .flexLayout(@direction: column; @justifyContent: flex-start;);
  margin-top: 24px;
  &-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 24px 24px;
    &-item {
      width: 208px;
      position: relative;
      cursor: pointer;
      &-banner {
        width: 100%;
        height: 156px;
        margin-bottom: 8px;
        overflow: hidden;
        border-radius: 8px;
        img {
          width: 100%;
          height: 156px;
          // object-fit: contain;
          border-radius: 8px;
        }
      }
    }
  }
  .subTitle {
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 8px;
    color: #222;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .design-desc {
    height: 20px;
    display: flex;
    align-items: center;
    img.avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 4px;
    }
    .info {
      flex: 1 0 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #666;
    }
  }
}
.loadArea {
  text-align: center;
  padding: 16px 0;
  .load-btn {
    background-color: #fff;
    
  }
  ::v-deep .ant-btn-primary span {
      color: #EF8150!important;
      font-weight: 500;
      text-shadow: none;
    }
}

@media (max-width: 1205px) {
  .hotIndustry {
    &-list {
      padding: 0 10px;
    }
  }
}

@media (max-width: 1054px) {
  .hotIndustry {
    &-list {
      justify-content: space-evenly;
      padding: 0;
    }
  }
}
</style>
